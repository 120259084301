<template>
    <div ref="panel" class="scroll-div flex">
        <div class="table-operate" style="margin: 0 20px">
            <el-button type="text" icon="el-icon-document" @click="exportInfo()">导出数据</el-button>
        </div>

        <div class="flex-column">
            <template v-if="message">
                <ts-error></ts-error>
            </template>
            <template v-else>
                <div :id="nameKey" class="canvas"></div>
                <ts-table ref="table" :cols="cols" :data="list" :style="{height:realHeight + 'px'}" style="width: 100%"
                          zero-to-empty full hide-detail hide-page></ts-table>
            </template>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['comCode', 'visitId'],
        data() {
            return {
                nameKey: 'newDrugQuarter',
                message: false,

                list: [],
                cols: this.$help.generalCols([
                    {fieldName: 'quarter', name: '季度', width: 60, listClass: 'tc'},
                    {fieldName: 'amount', name: `销售额（${this.$variable.unit}）`, width: 100, listClass: 'tc'},
                    {fieldName: 'rate', name: '增长率(%)', width: 100, listClass: 'tc'},
                ]),
                realHeight: 100,
            }
        },
        mounted() {
            this.realHeight = this.$refs.panel.clientHeight
            this.$api.get('drug/saleYear/sumQuarterAmountAndRateByComCode', {comCode: this.comCode}, {'Visit-Id': this.$help.getVisitId(this.visitId)})
                .then(res => {
                    if (res.code === this.$variable.successCode) {
                        this.list = res.data.map(item => {
                            // item.quarter = this.$variable.quarterMap[item.quarter]
                            item.rate = this.$help.parseFloat100(item.rate)
                            return item
                        })
                        this.$help.renderBar(this.nameKey, this.list, 'quarter', {}, false)
                    } else {
                        this.message = true
                    }
                })
        },
        methods: {
            exportInfo(txt = '确定导出吗', query = {}) {
                this.$store.commit('confirm', {
                    content: txt,
                    callback:(next, back) => {
                        this.$api.get('drug/saleYear/exportSumQuarterAmountAndRateByComCode', this.$help.joinObj({comCode: this.comCode}, query))
                            .then(res => {
                                if (res.code === '422') {
                                    next()
                                    this.$store.commit('error', res.message)
                                } else if (res.code === '403') {
                                    next()
                                    this.exportInfo(res.message, {force: true})
                                } else {
                                    this.$help.getExportStatus(res.data.id, data => {
                                        if (data === true) {
                                            next()
                                            this.$store.commit('success', '导出成功')
                                        } else {
                                            back()
                                            this.$store.commit('error', data)
                                        }
                                    })
                                }
                            })
                            .catch(back)
                    },
                })
            },
        }
    }
</script>